@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 15px;
}
::-webkit-scrollbar {
  /* width: 5px;
  height: 5px; */
}
::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 75.7%, 1);
  border-radius: 6px;
  background-clip: padding-box;
  min-height: 28px;
}
:root {
  --shadowShift: 0px;
  --shadowBlur: 0px;
  --circleColor: rgb(255 234 70);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nav-link:focus,
.nav-link:hover {
  color: #fbc355;
}

body {
  background: #1e593d;
  background: #206344;

  overflow-x: hidden;
}
.modal-content {
  background: transparent;
  padding: 2px;
  border: none;
}
.modal-content .modal-inner {
  background: transparent;
}
a.channel {
  color: #ffea46;
  margin: 30px 0px 0 -7px;
  display: block;
  font-size: 1rem;
  text-decoration: none;
}
.top-content a.channel {
  margin: 15px 0px 0 0px;
}
a.channel img {
  width: 40px;
}
a.channel:hover {
  color: #9ff4f0;
}
.intro-video video {
  /* width: auto !important; */
  /* height: auto !important;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes reverserotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes reverserotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes bounceimg {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-30px) scale(1.15);
  }
}

@-moz-keyframes bounceimg {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-30px) scale(1.15);
  }
}

@-o-keyframes bounceimg {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-30px) scale(1.15);
  }
}
@keyframes bounceimg {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-30px) scale(1.15);
  }
}

.wave__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.wave__circle {
  position: absolute;
  background-color: transparent;
  border: 6px solid var(--circleColor);
  /* border-radius: 50%; */
  box-shadow: inset var(--shadowShift) var(--shadowShift) var(--shadowBlur)
      rgba(159, 244, 240, 0.2),
    var(--shadowShift) var(--shadowShift) var(--shadowBlur)
      rgba(159, 244, 240, 0.2),
    inset calc(var(--shadowShift) * -1) calc(var(--shadowShift) * -1)
      var(--shadowBlur) rgba(159, 244, 240, 0.2),
    calc(var(--shadowShift) * -1) calc(var(--shadowShift) * -1)
      var(--shadowBlur) rgba(159, 244, 240, 0.2);
  filter: blur(1px);

  animation-name: ripple;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
  animation-iteration-count: infinite;
  z-index: 0;
}

.wave__container .wave__circle:nth-child(2) {
  animation-delay: -1s;
}

.wave__container .wave__circle:nth-child(3) {
  animation-delay: -3s;
}
.lng-btn img {
  width: 25px;
  margin: 10px;
}
.lang-dropdown li:first-child {
  border-bottom: 1px solid #9ff4f0;
}
@keyframes ripple {
  0% {
    top: 400px;
    left: 300px;

    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 800px;
    height: 800px;
    opacity: 0.2;
  }
}
@keyframes smallripple {
  0% {
    top: 50%;
    left: 50%;

    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
}
/* .shadow {
   -moz-box-shadow:    inset 0 0 10px #000000;
   -webkit-box-shadow: inset 0 0 10px #000000;
   box-shadow:         inset 0 0 10px #000000;
} */

.typing {
  position: relative;
  background: #ffea46;
  background: -webkit-linear-gradient(
    to bottom right,
    #ffea46 0%,
    #fde8c0 50%,
    #ffea46 100%
  );
  background: -moz-linear-gradient(
    to bottom right,
    #ffea46 0%,
    #fde8c0 50%,
    #ffea46 100%
  );
  background: linear-gradient(
    to bottom right,
    #ffea46 0%,
    #fde8c0 50%,
    #ffea46 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.typing::after {
  content: "_";
  position: absolute;
  right: 0;
  width: 100%;
  color: white;
  background: #3e7159;
  -webkit-animation: typing 1s steps(16) forwards, caret 1s infinite;
  animation: typing 1s steps(16) forwards, caret 1s infinite;
}

@-webkit-keyframes typing {
  to {
    width: 0;
  }
}

@keyframes typing {
  to {
    width: 0;
  }
}
@-webkit-keyframes caret {
  50% {
    color: transparent;
  }
}
@keyframes caret {
  50% {
    color: transparent;
  }
}
.second-sec,
div#momo {
  background: linear-gradient(#8051d6 0%, rgb(73, 34, 134) 100%);
  position: relative;
}
.video-sec {
  background: #206344;
  position: relative;
}
.video-sec .svg-border {
  bottom: -27px;
}
.vision {
  background: #1e593d;
}
.svg-border {
  position: absolute;
  bottom: -3px;
  width: 100%;
}
.svg-border svg {
  max-height: 16px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
}
div#nft,
.footer {
  background: #1e593d;
}
