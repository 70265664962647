.border-left {
  border-left: 1px solid #fff;
}
.nav-link {
  padding: 0rem 1rem;
  color: #fff;
}
#myVideo {
  width: 100%;
}
.title {
  font-size: 3.4rem;
  background: #cac9c5;
  background: -webkit-linear-gradient(to right, #ffea46 0%, #fde8c0 50%);
  background: -moz-linear-gradient(to right, #ffea46 0%, #fde8c0 50%);
  background: linear-gradient(to right, #ffea46 0%, #fde8c0 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  margin: 0;
  width: max-content;
}
.header {
  padding-top: 15px;
}
.cooming .modal-header .btn-close {
  display: none;
}
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  bottom: 0;
  right: 0;
  background: #000;
}
.video-end {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
.cooming .modal-header {
  border: none;
  justify-content: center;
}
.title2,
.title1 {
  font-size: 3.4rem;
  color: transparent;
  font-weight: bolder;
  width: max-content;
}
.title1 {
  margin: 0;
}
.title2 .small {
  font-size: 1.2rem;
}
.logo {
  width: 80%;
}
.title3 {
  font-size: 3.4rem;
  background: #ffea46;
  background: -webkit-linear-gradient(
    to bottom right,
    #ffea46 0%,
    #fde8c0 50%,
    #ffea46 100%
  );
  background: -moz-linear-gradient(
    to bottom right,
    #ffea46 0%,
    #fde8c0 50%,
    #ffea46 100%
  );
  background: linear-gradient(
    to bottom right,
    #ffea46 0%,
    #fde8c0 50%,
    #ffea46 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  width: max-content;
}
.title3 .small {
  font-size: 1rem;
  font-style: italic;
  -webkit-text-fill-color: #ffea62;
  -webkit-background-clip: unset;
}
.title3 .small a {
  color: #ffea62;
}
.title3 .small a:hover {
  color: #9ff4f0;
}
.top-content {
  background: #3e7159;
  /* background: rgba(30, 89, 61, 0.6); */
  min-height: 750px;
  position: relative;
}
.cnt-bg {
  /* border-bottom-right-radius: 5px;
    padding: 1px;
    box-shadow: 2px 2px 0 #5d01cd;
    background: linear-gradient( to bottom right, #9ff4f000 0%, #ffea4600 50%, #9FF4F0 100% ); */
  box-shadow: 0 0px 0px 0 #9ff4f033, 0 6px 20px 0 #9ff4f030;
  padding: 25px 20px 20px;
  border-radius: 5px;
}
.inner {
  /* display: flow-root;
    background: #1e593d;
    border-bottom-right-radius: 5px;
    padding: 0 10px; */
}
.video-bg {
  position: absolute;
  z-index: -1;
  display: none;
}
.intro {
  /* min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center; */
}
.arrow-container {
  background: transparent;
  width: 100px;
  height: 100px;
  position: absolute;
  transform: rotate(45deg);
  bottom: -48px;
  right: calc(50% + 100px);
  border: 2px solid #9ff4f0;
  z-index: 10;
  box-shadow: 0 0px 5px 0 #ffffff54, 0px 0px 10px 0 #ffffff30;
}
.landing-btn {
  display: none;
}
.top-img {
  position: absolute;
  width: 90%;
  right: 0;
  top: -20px;
  z-index: 10;
  /* max-height: 700px; */
  /* -webkit-clip-path: polygon(
    0% 15%,
    15% 0%,
    100% 0%,
    100% 85%,
    85% 100%,
    0% 100%
  ); */
}
.lng-container {
  position: relative;
}
.lng-container:hover .lang-dropdown {
  opacity: 1;
  visibility: visible;
}
.lng {
  fill: #fff;
}
.lng-btn {
  background: transparent;
  border: none;
  width: 100%;
}
.lang-dropdown .lng-btn:hover {
  background: #9ff4f0;
}
.lang-dropdown {
  position: absolute;
  width: 100px;
  height: auto;
  list-style: none;
  padding: 0;
  right: 50%;
  background: #fff;
  top: 30px;
  border-radius: 4px;
  z-index: 15;
  transition: opacity 600ms ease-out, visibility 600ms ease-out;
  will-change: opacity, visibility;
  opacity: 0;
  transform: translate(50%);
  visibility: hidden;
}
.feature img {
  width: 55%;
  animation: bounceimg 4s infinite;
  -webkit-animation: bounceimg 4s infinite;
  -moz-animation: bounceimg 4s infinite;
  -o-animation: bounceimg 4s infinite;
}

.rect-border {
  border: 4px solid #9ff4f0;
  width: 90%;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* -webkit-clip-path: polygon(
    0% 35%,
    4% 0%,
    100% 0%,
    100% 65%,
    96% 100%,
    0% 100%
  ); */
}
.rect-border::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  border-bottom: 4px solid #9ff4f0;
  transform: rotate(135deg);
  bottom: -16px;
  z-index: 0;
  right: -16px;
  background: #3e7159;
}
.rect-border::before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  border-bottom: 4px solid #9ff4f0;
  transform: rotate(-45deg);
  top: -16px;
  z-index: 0;
  left: -16px;
  background: #3e7159;
}
.btn-rect {
  background: #ffea46;
  border-radius: 0;
  position: relative;
  box-sizing: border-box;
  -webkit-clip-path: polygon(
    0% 40%,
    10% 0%,
    100% 0%,
    100% 60%,
    90% 100%,
    0% 100%
  );
  height: 44px;
  margin: 4px 4px;
  font-weight: 600;
  width: 180px;
  color: #5d01cd;
  border: none;
}

.border-right {
  border-right: 1px solid #fff;
}
.nav-item .btn-rect {
  width: 100px;
  height: 29px;
  font-size: 13px;
  line-height: 1;
}
.btn-rect:hover {
  /* background: #9ff4f0; */
  color: #5d01cd;
}
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.btn-rect::after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #9ff4f0;
}
.btn-rect:hover::after {
  width: 100%;
}
.feat {
  margin: 15px 10px;
  font-size: 13px;
}
.feat.border-left {
  padding-left: 10px;
}
.feat p {
  font-size: 14px;
}
.flex {
  display: flex;
}
.top-img-container {
  position: relative;
}
.img-fixed {
  /* max-height: 700px; */
  width: 85%;
  margin-top: -50px;
}
.arrow-down {
  transform: rotate(-45deg);
  width: 100px;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: arrowFram 2s infinite;
}
.momo p {
  font-size: 15px;
  margin-bottom: 15px;
}
@keyframes arrowFram {
  0%,
  100% {
    transform: rotate(-45deg) translateY(-10pm);
  }
  50% {
    transform: rotate(-45deg) translateY(10px);
  }
}
.other-content {
  /* background: #1e593d; */
}
.align-center {
  text-align: center;
  margin: 0 auto;
}
.border-blck {
  border-bottom: 2px solid #9ff4f0;
  /* transform: scale(0);
  -webkit-animation: landZoomIn 0.7s forwards;
  animation: landZoomIn 0.7s forwards; */
  position: relative;
}
.border-blck::before {
  content: "";
  width: 100px;
  height: 8px;
  background: #9ff4f0;
  position: absolute;
  transform: skewX(30deg);
  top: -3px;
}

@keyframes landZoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.counter {
  padding: 20px 0;
  border-radius: 5px;
}

.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color: #fde8c0;
}

.count-text {
  /* font-size: 13px; */
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color: #fde8c0;
}
.btn-rect.join {
  width: 160px;
  height: 60px;
  -webkit-clip-path: polygon(
    0% 45%,
    15% 0%,
    100% 0%,
    100% 55%,
    85% 100%,
    0% 100%
  );
  font-size: 20px;
}
.position-relative {
  position: relative;
}
.timer-bg {
  width: 65%;
  position: absolute;
  bottom: 30%;
  right: 0;
  height: 120px;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -webkit-clip-path: polygon(
    0% 30%,
    7% 0%,
    100% 0%,
    100% 70%,
    93% 100%,
    0% 100%
  );
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
}
.timer-container {
  position: absolute;
  display: flex;
  width: 65%;
  height: 120px;
  justify-content: space-between;
  text-align: center;
  -webkit-clip-path: polygon(
    0% 30%,
    7% 0%,
    100% 0%,
    100% 70%,
    93% 100%,
    0% 100%
  );
  padding: 10px 25px;
  bottom: 30%;
  right: 0;
  flex-wrap: wrap;
  align-content: center;
  background-color: rgb(0 0 0 / 60%);
  backdrop-filter: blur(8px);
}
.time-stack {
  width: 30%;
}
p.time-txt {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
}
span.time {
  font-size: 2.8rem;
  background: linear-gradient(to right, #ffea46 0%, #fde8c0 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  line-height: 1.2;
}
.time-stack:not(:last-child) {
  border-right: 2px solid #ffea46;
}
.moboxchain {
  padding: 250px 0;
}
.moboxchain-body {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
}
.line-left {
  margin-right: 0%;
  position: relative;
}
.line-right {
  margin-left: 0%;
  position: relative;
}
.line-left .line {
  width: 100%;
  opacity: 0;
}
.line-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.moboxchain-body .fadeIn {
  -webkit-animation: landFadeIn 0.7s forwards;
  animation: landFadeIn 0.7s forwards;
}
@keyframes landFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinker {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.line-bg .liner-box {
  width: 30%;
  height: 1.8%;
  overflow: hidden;
  border-radius: 15px;
}
.line-bg .liner-1 {
  position: absolute;
  top: 20%;
  left: -2%;
  width: 24%;
}
.line-bg .liner-2 {
  position: absolute;
  top: 78%;
  left: -2%;
  width: 24%;
}
.line-bg .liner-3 {
  position: absolute;
  top: 6%;
  left: 20%;
  width: 24%;
  transform: rotate(122deg);
}
.line-bg .liner-4 {
  position: absolute;
  top: 6%;
  left: 61%;
  width: 24%;
  transform: rotate(60deg);
}
.line-bg .liner-5 {
  position: absolute;
  top: 35%;
  left: 20%;
  width: 22%;
  transform: rotate(60deg);
}
.line-bg .liner-6 {
  position: absolute;
  top: 35%;
  left: 61%;
  width: 23%;
  transform: rotate(302deg);
}
.line-bg .liner-7 {
  position: absolute;
  top: 49%;
  left: 40%;
  width: 22%;
  transform: rotate(0deg);
}
.line-bg .liner-8 {
  position: absolute;
  top: 21%;
  left: 83%;
  width: 24%;
  transform: rotate(0deg);
}
.line-bg .liner-9 {
  position: absolute;
  top: 49%;
  left: 69%;
  width: 24%;
  transform: rotate(0deg);
}
.line-bg .liner-10 {
  position: absolute;
  top: 79%;
  left: 82%;
  width: 22%;
  transform: rotate(0deg);
}
.line-bg .liner-11 {
  position: absolute;
  top: 64%;
  left: 20%;
  width: 22%;
  transform: rotate(-60deg);
}
.line-bg .liner-12 {
  position: absolute;
  top: 64%;
  left: 62%;
  width: 23%;
  transform: rotate(59deg);
}
.line-bg .liner-13 {
  position: absolute;
  top: 93%;
  left: 20%;
  width: 24%;
  transform: rotate(-121deg);
}
.line-bg .liner-14 {
  position: absolute;
  top: 94%;
  left: 60%;
  width: 23%;
  transform: rotate(-60deg);
}
.line-bg .liner-node {
  position: absolute;
  width: 5%;
  -webkit-animation: blinker 2s linear infinite;
  animation: blinker 2s linear infinite;
}
.line-bg .liner-node-1 {
  top: 19%;
  left: 22%;
}

.line-bg .liner-node-2 {
  top: 76%;
  left: 22%;
}
.line-bg .liner-node-3 {
  top: 47%;
  left: 35%;
}
.line-bg .liner-node-4 {
  top: 47%;
  left: 63%;
}
.line-bg .liner-node-5 {
  top: 19%;
  left: 78%;
}
.line-bg .liner-node-6 {
  top: 77%;
  left: 77%;
}
.line-bg .liner {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #9ff4f0);
  border-radius: 15px;
  -webkit-animation: goSlide 2s linear infinite;
  animation: goSlide 2s linear infinite;
}
@keyframes goSlide {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
.mbox {
  width: 25%;
}

.line-right .line {
  opacity: 0;
  width: 100%;
  transform: rotate(180deg);
}
.line-left .avatar-1 {
  position: absolute;
  width: 50%;
  left: -15%;
  top: 50%;
  transform: translateY(-50%);
}
.line-left .avatar-1 .avatar {
  width: 100%;
}
.moboxchain-body .zoomInBack {
  transform: scale(0);
  -webkit-animation: landZoomIn 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  animation: landZoomIn 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
@keyframes landZoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.moboxchain-body .hash {
  display: none;
}
.moboxchain-body .line-left .avatar-1 .hash {
  width: 35%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20%;
}
.line-left .avatar-1 .hash .hash-bg {
  width: 100%;
}
.moboxchain-body .hash .hash-box {
  position: absolute;
  width: 100%;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 18px;
}
.moboxchain-body .hash .hash-box .dig {
  width: 20%;
}
.moboxchain-body .line-left .avatar-2 {
  position: absolute;
  width: 50%;
  left: 27%;
  top: -5%;
}
.moboxchain-body .line-left .avatar-2 .avatar {
  width: 100%;
}
.moboxchain-body .line-left .avatar-2 .hash {
  width: 35%;
  position: absolute;
  top: -20%;
  right: 10%;
}
.moboxchain-body .line-left .avatar-2 .hash .hash-bg {
  width: 100%;
}
.moboxchain .moboxchain-body .hash .hash-box {
  position: absolute;
  width: 100%;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 18px;
}
.moboxchain-body .hash .hash-box .dig {
  width: 30%;
}
.moboxchain-body .line-left .avatar-3 {
  position: absolute;
  width: 50%;
  left: 27%;
  bottom: -5%;
}
.moboxchain-body .line-left .avatar-3 .avatar {
  width: 100%;
}
.moboxchain-body .line-left .avatar-3 .hash {
  width: 35%;
  position: absolute;
  bottom: -20%;
  right: 10%;
}
.moboxchain-body .line-left .avatar-3 .hash .hash-bg {
  width: 100%;
}
.moboxchain-body .rotateInS {
  transform: scale(0);
  -webkit-animation: landRotateInS 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  animation: landRotateInS 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
@keyframes landRotateInS {
  0% {
    transform: scale(0) rotate(-1turn);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
.moboxchain-body .line-right .avatar-4 {
  position: absolute;
  width: 50%;
  right: -15%;
  top: 50%;
  transform: translateY(-50%);
}
.moboxchain-body .line-right .avatar-4 .avatar {
  width: 100%;
}
.moboxchain-body .line-right .avatar-4 .hash {
  width: 35%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20%;
}
.moboxchain-body .line-right .avatar-4 .hash .hash-bg {
  width: 100%;
}
.moboxchain-body .line-right .avatar-5 {
  position: absolute;
  width: 50%;
  right: 27%;
  top: -5%;
}
.moboxchain-body .line-right .avatar-5 .avatar {
  width: 100%;
}
.moboxchain-body .line-right .avatar-5 .hash {
  width: 35%;
  position: absolute;
  top: -20%;
  left: 10%;
}
.moboxchain-body .line-right .avatar-5 .hash .hash-bg {
  width: 100%;
}
.moboxchain-body .line-right .avatar-6 {
  position: absolute;
  width: 50%;
  right: 27%;
  bottom: -5%;
}
.moboxchain-body .line-right .avatar-6 .avatar {
  width: 100%;
}
.moboxchain-body .line-right .avatar-6 .hash {
  width: 35%;
  position: absolute;
  bottom: -20%;
  left: 10%;
}
.moboxchain-body .hash > img {
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}
.moboxchain-body .line-right .hash > img {
  -webkit-animation: reverserotating 5s linear infinite;
  -moz-animation: reverserotating 5s linear infinite;
  -ms-animation: reverserotating 5s linear infinite;
  -o-animation: reverserotating 5s linear infinite;
  animation: reverserotating 5s linear infinite;
}
.moboxchain-body .line-right .avatar-6 .hash .hash-bg {
  width: 100%;
}
.header-txt {
  width: 70%;
}
.header-txt li {
  cursor: pointer;
  list-style: none;
  margin-bottom: 1rem;
  position: relative;
  width: fit-content;
  padding-bottom: 2px;
}
.header-txt ul {
  padding-left: 2.5rem;
}
.header-txt .title {
  font-size: 1.8rem;
  width: auto;
}
.header-txt .active .title {
  background: #9ff4f0;
  -webkit-background-clip: text;
}
.bottom-video video {
  object-fit: cover;
  -webkit-clip-path: polygon(
    0% 15%,
    15% 0%,
    100% 0%,
    100% 85%,
    85% 100%,
    0% 100%
  );
  clip-path: polygon(0% 15%, 15% 0%, 100% 0%, 100% 85%, 85% 100%, 0% 100%);
  border-radius: 20px;
}
.bottom-video {
  filter: drop-shadow(0 0 10px #9ff4f033);
  position: relative;
  width: 80% !important;
  margin: 0 auto;
}
.content-txt {
  padding-left: 2.5rem;
  padding-top: 2rem;
  /* padding-right: 4rem; */
  width: 100%;
}
.content-txt .title {
  font-size: 3.4rem;
  width: auto;
}
.content-txt .title.third {
  font-size: 2.9rem;
}
.content-txt h5 {
  font-size: 16px;
  line-height: 1.6;
}
.bounceUpIn {
  opacity: 0;
  -webkit-animation: landBounceUpIn 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  animation: landBounceUpIn 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
}
@keyframes landBounceUpIn {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.bottom-img {
  margin-top: -70px;
  width: 90%;
}
.left-border-blck {
  border-left: 2px solid #9ff4f0;
  position: relative;
  max-height: 720px;
  min-height: 550px;
}
.left-border-blck::before {
  content: "";
  height: 100px;
  width: 8px;
  background: #9ff4f0;
  position: absolute;
  transform: skewY(30deg);
  left: -5px;
}
.social img {
  width: 30px;
  margin-right: 1rem;
  cursor: pointer;
}
.footer .nav-link {
  padding: 0rem 1rem 0 0;
  color: #fff;
  font-size: 13px;
}
.intro-block h4 {
  font-weight: 600;
  color: #fde8c0;
  margin-top: 10px;
}
.copyright {
  font-size: 13px;
}
.foo {
  justify-content: flex-end;
}
.foo .nav-link {
  padding: 0rem 1rem;
}
.foo .nav-item:last-child .nav-link {
  padding-right: 0;
}
.bold {
  color: #000;
}
.mobile-show {
  display: none;
}
.mobile-bg {
  opacity: 0;
  position: fixed;

  top: 68px;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(16, 15, 15, 0.6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(5px);
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
}
body.sidebar-open {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
}
.sidebar-menu {
  position: fixed;
  z-index: 90;
  top: 68px;
  left: 0;
  width: 100%;
  height: 100%;
  left: -100%;
}
.open .mobile-bg {
  opacity: 1;
  z-index: 90;
  left: 0;
}
.open .sidebar-menu {
  left: 0;
  transition: left 0.25s ease-in-out;
}

.li-active {
  position: absolute;
  bottom: 1px;
  width: 100%;
  height: 0;
  border-width: 0 10px 4px 0;
  border-style: none solid solid;
  border-color: transparent transparent #9ff4f0;
  transition: all 0.3s;
  animation-delay: 0.3s;
  transform-origin: left center;
  transform: scale(0);
}
.header-txt li.active .li-active {
  transform: scale(1);
}
.timeline {
  padding-left: 0;
  margin: 1rem 0;
}
.timeline .timeline-item {
  padding-bottom: 2.5rem;
}
.timeline .timeline-item {
  position: relative;
  list-style: none;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  overflow: hidden;
}

.timeline .timeline-item .timeline-line {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: block;
  margin-top: 0.25rem;
  margin-right: 1rem;
  position: relative;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5d01cd;
  -webkit-box-shadow: 4px 2px 0 #5d01cd;
  box-shadow: 4px 2px 0 #5d01cd;
  background: #ffea46;
}
.timeline-line .title {
  color: #5d01cd;
  font-size: 2.8rem;
  background: none;
  -webkit-text-fill-color: unset;
}
.timeline .timeline-item .timeline-line {
  width: 80px !important;
  height: 80px !important;
  margin-right: 2rem;
}
.title3.vision {
  padding-left: 2.5rem;
}
.timeline .timeline-item .timeline-content {
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
  display: flex;
  padding: 2px;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  /* border: 2px solid #000; */
  -webkit-box-shadow: 2px 2px 0 #5d01cd;
  box-shadow: 2px 2px 0 #5d01cd;
  background: -webkit-linear-gradient(
    to bottom right,
    #9ff4f0 0%,
    #ffea46 50%,
    #9ff4f0 100%
  );
  background: -moz-linear-gradient(
    to bottom right,
    #9ff4f0 0%,
    #ffea46 50%,
    #9ff4f0 100%
  );
  background: linear-gradient(
    to bottom right,
    #9ff4f0 0%,
    #ffea46 50%,
    #9ff4f0 100%
  );
}
.timeline-inner {
  padding: 1rem;
  background: #1e593d;
  border-radius: 6px;
}
.timeline .timeline-item .timeline-content p,
.timeline .timeline-item .timeline-content h4 {
  color: #fff;
}
.timeline .timeline-item .timeline-line:after,
.timeline .timeline-item .timeline-line:before {
  content: "";
  height: 80px;
  width: 4px;
  position: absolute;
  margin-left: -1px;
  left: 50%;
  z-index: -1;
}
.timeline .timeline-item .timeline-line:after {
  top: 1rem;
}

.timeline .timeline-item:not(:last-child) .timeline-line:after {
  height: 700px !important;
}
.timeline .timeline-item:first-child .timeline-line:before,
.timeline .timeline-item:last-child .timeline-line:after {
  height: 0;
}
.timeline .timeline-line:after,
.timeline .timeline-line:before {
  background: #fde8c0;
  width: 4px;
}
.timeline .timeline-item:not(:first-child) .timeline-line:before {
  top: -1rem;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .top-content {
    /* min-height: 645px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bottom-video {
    width: 85% !important;
    min-height: 670px;
  }
  .video-bg {
    display: none;
  }
  .svg-border {
    bottom: -6px;
  }
  .video-sec .svg-border {
    bottom: -30px;
  }
  .top-content {
    background: #3e7159;
    min-height: auto;
  }
  .title,
  .title1,
  .title2,
  .title3 {
    width: auto;
    font-size: 2.3rem;
  }
  .wave__circle {
    animation-name: smallripple;
  }
  .top-img {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }
  .rect-border {
    width: 100%;
    height: 55px;
  }

  .intro br {
    display: none;
  }
  .btn-rect {
    font-size: 11px;
    width: 120px;
    height: 38px;
    -webkit-clip-path: polygon(
      0% 40%,
      11% 0%,
      100% 0%,
      100% 60%,
      87% 100%,
      0% 100%
    );
  }
  .feat:last-child {
    display: none;
  }
  .arrow-container {
    right: calc(50% - 50px);
    width: 80px;
    height: 80px;
    bottom: -40px;
  }
  .arrow-down {
    width: 70px;
  }
  .milestone .col-md-3 {
    width: 50%;
  }
  .milestone .title3 {
    margin: 0 !important;
    /* padding: 0 !important; */
  }
  .vision {
    margin: 0 !important;
  }
  .vision .left {
    padding-right: 0 !important;
  }
  .vision .right {
    padding-top: 0 !important;
  }
  .intro-block {
    padding-top: 0 !important;
  }
  .timer-container {
    width: 87%;
    bottom: 45%;
    right: -20px;
  }
  span.time {
    font-size: 2rem;
  }
  p.time-txt {
    font-size: 0.9rem;
  }
  .momo .col-md-8 {
    width: 100%;
  }
  .hash-box .bold {
    font-size: 9px;
    line-height: 1;
  }
  .header-txt,
  .content-txt {
    width: 100%;
  }
  .header-txt .title {
    font-size: 1.8rem;
  }
  .content-txt .title {
    font-size: 2.8rem;
  }
  .left-border-blck {
    min-height: 450px;
  }
  .feat {
    margin: 15px 5px;
  }
  .feat p {
    font-size: 12px;
  }
  .mobile-show {
    align-items: center;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  .mobile-hide {
    display: none;
  }
  .logo {
    width: 140px;
    max-width: unset;
  }
  .feature {
    justify-content: center;
  }
  .feature .col {
    flex: none;
    width: 33%;
  }
  #nft .col-md-6 {
    width: 100%;
  }
  .bottom-img {
    width: 70%;
    margin-top: 0;
  }
  .nft-img {
    text-align: center !important;
  }
  .foo .nav-link {
    padding: 0rem 0.8rem;
  }
  #milestone .pt-md-5 {
    padding-top: 0 !important;
    margin-top: -30px;
  }
  .nav {
    align-items: center;
  }
}
@media (max-width: 1199.98px) {
  .feat:last-child {
    display: none;
  }
  .rect-border {
    width: 100%;
  }
  .feat:last-child {
    display: block;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .title,
  .title1,
  .title2,
  .title3,
  .content-txt .title {
    width: auto;
    font-size: 2.8rem;
  }
  .wave__circle {
    animation-name: smallripple;
  }
  .svg-border {
    bottom: -6px;
  }
  .video-sec .svg-border {
    bottom: -30px;
  }
  .top-img {
    width: 100%;
    position: relative;
  }
  .arrow-container {
    right: calc(50% - 50px);
  }
  .top-content {
    padding-bottom: 40px;
  }
  .col-md-6 {
    width: 50%;
  }
  .feat:last-child {
    display: none;
  }
  .vision {
    margin: 0 !important;
  }
  .vision .left {
    padding-right: 0 !important;
  }
  .vision .right {
    padding-top: 0 !important;
  }
  .intro-block {
    padding-top: 0 !important;
  }
  .timer-container {
    width: 87%;
    bottom: 35%;
    right: -20px;
  }
  .hash-box .bold {
    font-size: 12px;
    line-height: 1;
  }
  .rect-border {
    width: 95%;
  }
  .left-border-blck {
    min-height: 650px;
  }
  .content-txt .title.third {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 600px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .bottom-video {
    width: 90% !important;
    min-height: 320px;
  }
  .sticky-top {
    text-align: center;
  }
  .sticky-top .img-fixed {
    /* width: 80%; */
  }
  body,
  html {
    overflow-x: hidden;
  }
  .svg-border {
    bottom: -8px;
  }
  .video-sec .svg-border {
    bottom: -8px;
  }
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .feat:last-child {
    display: none;
  }
  .feat.border-left {
    padding-left: 7px;
  }
  .feat {
    margin: 15px 3px;
    font-size: 13px;
  }
  .intro br {
    display: none;
  }
  .intro {
    padding-top: 5rem;
  }
  .landing-btn {
    display: block;
  }
  .bottom-video {
    margin-top: 3rem;
  }
  .nav {
    align-items: center;
  }
  .nav-link {
    padding: 0rem;
  }
  .mobile-show {
    display: block;
  }
  .mobile-hide {
    display: none !important;
  }
  .top-content {
    padding-bottom: 70px;
  }
  .logo.img-fluid {
    width: 150px;
  }
  .wave__circle {
    animation-name: smallripple;
  }
  .title,
  .title1,
  .title2,
  .title3 {
    width: auto;
    font-size: 1.7rem;
    text-align: center;
    line-height: 1.5;
  }
  .content-txt .title.third {
    font-size: 1.7rem;
  }
  .intro p {
    text-align: justify;
  }
  .rect-border {
    width: 100%;
    /* -webkit-clip-path: polygon(
      0% 38%,
      6% 0%,
      100% 0%,
      100% 64%,
      94% 100%,
      0% 100%
    ); */
    padding-left: 10px !important;
  }

  .feature {
    justify-content: center;
  }
  .feature .col {
    flex: none;
    width: 50%;
  }
  .feat {
    /* margin: 5px; */
    text-align: center;
  }
  .feat p {
    font-size: 14px;
    margin: 0;
  }
  .rect-border .btn-rect {
    font-size: 14px;
    width: 120px;
    -webkit-clip-path: polygon(
      0% 40%,
      15% 0%,
      100% 0%,
      100% 60%,
      85% 100%,
      0% 100%
    );
  }
  .top-img {
    width: 100%;
    position: relative;
    margin-top: 30px;
  }
  .img-fixed {
    width: 100%;
    margin-top: 0;
  }
  .arrow-down {
    width: 70px;
  }
  .arrow-container {
    width: 80px;
    height: 80px;
    right: calc(50% - 40px);

    bottom: -40px;
  }
  .timer-container {
    width: 80%;
    bottom: 10%;
    right: 10px;
    height: 100px;
  }
  span.time {
    font-size: 2.2rem;
  }
  p.time-txt {
    font-size: 0.9rem;
  }
  .intro-block {
    text-align: justify;
    margin-top: 1rem;
  }
  .timeline {
    margin-top: 3rem;
  }
  .moboxchain-body {
    /* transform: translate(-8.5%); */
    /* width: 121%; */
    width: 90%;
  }
  .hash-box .bold {
    font-size: 7px !important;
    line-height: 10px !important;
  }
  .header-txt {
    width: 100%;
  }
  .content-txt {
    width: 100%;
    text-align: justify;
    min-height: 430px;
  }
  .bottom-img {
    margin-top: 0px;
  }
  .vision .left {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .header-txt ul {
    overflow: auto hidden;
    white-space: nowrap;
    margin-left: 2.5rem;
    padding: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .header-txt ul::-webkit-scrollbar {
    display: none;
  }

  .header-txt li {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 1.25rem;
    padding-bottom: 5px;
  }
  .header-txt .title {
    font-size: 20px;
  }
  .nav .btn-rect {
    width: 100px;
    height: 29px;
    font-size: 13px;
    line-height: 1;
  }
  .content-txt .title {
    font-size: 2rem;
  }
  .left-border-blck {
    min-height: 450px;
  }
  .foo .nav-link {
    padding: 0rem 0.5rem;
  }
  .footer-logo-blck {
    text-align: center !important;
    margin: 30px 0;
  }
  .foo {
    justify-content: center;
  }
  .mobile-show.navbar-dark {
    align-items: center;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  .navbar-toggler {
    border: none;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .header {
    margin-bottom: 20px;
  }
  .sidebar-menu .nav-link {
    font-size: 1.8rem;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
  }
  footer.other-content.footer {
    padding-bottom: 15px;
    /* margin-top: 20px; */
    text-align: center;
  }
  .copyright {
    text-align: center;
  }
  .footer-nav {
    padding-top: 1rem !important;
    justify-content: center;
  }
  .footer .footer-nav .nav-link {
    padding: 0rem 1rem 0 0;
  }
  .social img {
    width: 25px;
    margin: 0 10px;
  }
  .momo p {
    text-align: justify;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
  }
}
@media (max-width: 320px) {
  .rect-border {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-bottom: 5px;
  }
  .timeline .timeline-item .timeline-line {
    width: 60px !important;
    height: 60px !important;
    margin-right: 1.5rem;
  }
  .timeline-line .title {
    font-size: 2rem;
  }
  .timeline .timeline-item .timeline-line:after,
  .timeline .timeline-item .timeline-line:before {
    height: 70px;
  }
  .timeline .timeline-item:not(:last-child) .timeline-line:after {
    height: 450px;
  }
  .bottom-video {
    min-height: 270px;
  }
  .content-txt {
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }
  .header-txt ul {
    margin-left: 1.5rem;
  }
}
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
}
